import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { FeaturesModule } from '../features/features.module';
import { CoreRoutingModule } from './core.routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    FeaturesModule,
    SharedModule
  ],
  exports: [
    CoreRoutingModule,
    FeaturesModule,
    SharedModule
  ]
})
export class CoreModule { }
