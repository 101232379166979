import { ErrorHandler, Injectable } from "@angular/core";

import { MyMonitoringService } from "../appInsights/appinsights.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private myMonitoringService: MyMonitoringService) {
        super();
    }

    override handleError(error: Error) {
        this.myMonitoringService.logException(error);
    }
}