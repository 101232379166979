import {
    OnInit,
    Component,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
    showInfoCard = false;

    constructor(
        private breakpointSvc: BreakpointObserver,
        private changeDetectorSvc: ChangeDetectorRef
    ) {
        this.initializeBreakpoints();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        
    }

    private initializeBreakpoints(): void {
        this.breakpointSvc
            .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
            .pipe(
                tap(value => console.log(value)),
                distinctUntilChanged(),
                untilDestroyed(this)
            ).subscribe((result: BreakpointState) => {
                this.breakpointChanged();
            });
    }

    private breakpointChanged(): void {
        if (this.breakpointSvc.isMatched('(min-width: 1026px)')) {
            this.showInfoCard = true;
        } else {
            this.showInfoCard = false;
        }
    }
}
