import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LeftNavRoutingModule } from './left-nav/left-nav.routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    LeftNavRoutingModule
  ],
  exports: [
    LeftNavRoutingModule
  ]
})
export class CoreRoutingModule { }
