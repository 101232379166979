<div class="top-menu">
    <ul>
      <li [routerLinkActive]="'active'">
        <a href="" #aboutRoute [routerLink]="['about']">
          <span class="icon ion-person"></span>
          <span class="link">About</span>
        </a>
      </li>
      <li [routerLinkActive]="'active'">
        <a href="" [routerLink]="['resume']">
          <span class="icon ion-android-list"></span>
          <span class="link">Resume</span>
        </a>
      </li>
      <li [routerLinkActive]="'active'">
        <a href="" [routerLink]="['works']">
          <span class="icon ion-paintbrush"></span>
          <span class="link">Works</span>
        </a>
      </li>
      <li [routerLinkActive]="'active'">
        <a href="" [routerLink]="['blog']">
          <span class="icon ion-chatbox-working"></span>
          <span class="link">Articles</span>
        </a>
      </li>
      <li [routerLinkActive]="'active'">
        <a href="" [routerLink]="['contact']">
          <span class="icon ion-at"></span>
          <span class="link">Contact</span>
        </a>
      </li>
    </ul>
</div>
