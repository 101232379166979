import { ConfigData } from "src/app/shared/models/config.data";

export const environment: ConfigData = {
    production: false,
    appInsights: {
      instrumentationKey: 'dcbf5c7f-8a39-42bd-841a-a9157c9c349d'
    },
    endpoints: {
      mailerApi: 'https://edge.libertyconsultingsvcllc.com/api/cvsmtp',
      blogApi: 'https://edge.libertyconsultingsvcllc.com/api/cvblog',
      identityApi: 'https://identity.libertyconsultingsvcllc.com/api/identity'
    }
    // endpoints: {
    //   mailerApi: 'https://localhost:7128/api/cvsmtp',
    //   blogApi: 'https://localhost:7128/api/cvblog',
    //   identityApi: 'https://localhost:7069/api/identity'
    // }
};
