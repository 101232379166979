import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';

import { MainComponent } from '../main/main.component';
import { LeftNavComponent } from './left-nav.component';
import { InfoCardComponent } from '../info-card/info-card.component';
import { InfoPageComponent } from '../info-page/info-page.component';

export const routes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'about'
  },
  {
    path: 'about',
    loadChildren: () => import('../../features/about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('../../features/blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('../../features/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'resume',
    loadChildren: () => import('../../features/resume/resume.module').then(m => m.ResumeModule)
  },
  {
    path: 'works',
    loadChildren: () => import('../../features/works/works.module').then(m => m.WorksModule)
  }
];

@NgModule({
  declarations: [
    ...LeftNavRoutingModule.components
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule,
    ...LeftNavRoutingModule.components
  ]
})
export class LeftNavRoutingModule {
  public static components: any[] = [
    InfoCardComponent,
    InfoPageComponent,
    MainComponent,
    LeftNavComponent
  ];
}
