<div class="profile no-photo">

    <!-- profile image -->
    <div class="slide" style="background-image: url(assets/images/tellek-liberty-profile.jpeg);"></div>

    <!-- profile titles -->
    <div class="title">Tellek Edward Liberty</div>
    <!--<div class="subtitle">Web Designer</div>-->
    <div class="subtitle subtitle-typed">
      <div class="typing-title">
        <p>Technical Lead&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Architect</p>
      </div>
    </div>

    <!-- profile socials -->
    <div class="social">
      <a title="#" rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/tellekliberty/"><span class="fa fa-linkedin"></span></a>
      <a title="#" rel="noopener noreferrer" target="_blank" href="https://twitter.com/tliberty"><span class="fa fa-twitter"></span></a>
      <a title="#" rel="noopener noreferrer" target="_blank" href="https://github.com/orgs/Liberty-Organization/teams/liberty"><span class="fa fa-github"></span></a>
      <a title="#" rel="noopener noreferrer" target="_blank" href="https://stackoverflow.com/"><span class="fa fa-stack-overflow"></span></a>
    </div>
    
    <!-- profile buttons -->
    <div class="lnks">
      <a href="assets/documents/TellekLibertyResumeSummary2023.pdf" class="lnk" download>
        <span class="text">Download CV</span>
        <span class="ion ion-archive"></span>
      </a>
      <a href="" [routerLink]="['contact']" class="lnk discover">
        <span class="text">Contact Me</span>
        <span class="arrow"></span>
      </a>
    </div>

</div>
