<div class="page new-skin">
		
  <!-- preloader -->
  <div class="preloader">
    <div class="centrize full-width">
      <div class="vertical-center">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- background -->
  <div class="background gradient">
    <ul class="bg-bubbles">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
  </div>

  <!--
    Container
  -->
  <div class="container opened" data-animation-in="fadeInLeft" data-animation-out="fadeOutLeft">

    <!--
      Header
    -->
    <header class="header">

      <!-- header profile -->
      <div class="profile">
        <div class="title">Tellek Liberty</div>
        <div class="subtitle subtitle-typed">
          <div class="typing-title">
            <p>SR Associate | Tech Lead | Architect</p>
          </div>
        </div>
      </div>

      <!-- menu btn -->
      <!--<a href="#" class="menu-btn"><span></span></a>-->

      <!-- menu -->
      <app-left-nav></app-left-nav>

    </header>

    <!--
      Card - Started
    -->
    <div class="card-started" id="home-card">

      <!--
        Profile
      -->
      <app-info-card *ngIf="showInfoCard === true"></app-info-card>

    </div>

    <router-outlet></router-outlet>

  </div>
  
</div>